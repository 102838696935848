var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu"},[[_c('div',{staticClass:"subpageHeader test"},[_c('div',{staticClass:"boxShadow",attrs:{"id":"nav"}},[_c('b-navbar',{attrs:{"type":"dark","toggleable":"lg","id":"topNav"}},[_c('b-navbar-brand',{attrs:{"to":"/"}},[_c('img',{staticClass:"logoImg",class:{
              margB:
                this.$route.name !== 'home',
            },attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/bvt/2024/06/Lable-Tour-Logo.png"}})]),_c('b-navbar-toggle',{directives:[{name:"b-modal",rawName:"v-b-modal.nav-collapse",modifiers:{"nav-collapse":true}}],staticClass:"ml-auto",attrs:{"type":"dark"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":"/"}},[_vm._v(" HOME ")]),_c('b-nav-item',{attrs:{"to":"/schedule"}},[_vm._v(" SCHEDULE ")]),(this.okfound == 'Y' && this.course !== null)?[_c('b-nav-item',{attrs:{"to":{
                  name: 'reports',
                  query: {
                    url: _vm.currentReport(),
                    id: _vm.season,
                    code: _vm.course,
                    fTour: true,
                    title: _vm.currentTitle(),
                  },
                }}},[_vm._v(" RESULTS ")])]:_vm._e(),_c('b-nav-item',{attrs:{"to":"/oom"}},[_vm._v(" OOM ")]),_c('b-nav-item',{attrs:{"to":"/tournaments-information"}},[_vm._v(" TOUR INFO ")]),_c('b-nav-item',{attrs:{"to":"/tour-incentives"}},[_vm._v(" TOUR INCENTIVES ")]),_c('b-nav-item',{attrs:{"to":"/membership"}},[_vm._v(" MEMBERSHIP ")]),_c('b-nav-item',{staticClass:"rightPix",attrs:{"to":"/news"}},[_vm._v(" NEWS ")])],2)],1),_c('b-modal',{attrs:{"hide-header":"","hide-footer":"","id":"nav-collapse"}},[_c('b-button',{staticClass:"closeBtn",on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_c('img',{staticClass:"closeIcon",attrs:{"src":"https://assets.asiantour.com/toyota-tour/2024/02/close_360.png"}})]),_c('div',{staticClass:"mobileMenu"},[_c('div',{staticClass:"menuGroup"},[_c('b-nav-item',{attrs:{"to":"/"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" HOME ")]),_c('b-nav-item',{attrs:{"to":"/schedule"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" SCHEDULE ")]),(this.okfound == 'Y' && this.course !== null)?[_c('b-nav-item',{attrs:{"to":{
                    name: 'reports',
                    query: {
                      url: _vm.currentReport(),
                      id: _vm.season,
                      code: _vm.course,
                      fTour: true,
                      title: _vm.currentTitle(),
                    },
                  }},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" RESULTS ")])]:_vm._e(),_c('b-nav-item',{attrs:{"to":"/oom"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" OOM ")]),_c('b-nav-item',{attrs:{"to":"/tournaments-information"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" TOUR INFO ")]),_c('b-nav-item',{attrs:{"to":"/tour-incentives"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" TOUR INCENTIVES ")]),_c('b-nav-item',{attrs:{"to":"/membership"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" MEMBERSHIP ")]),_c('b-nav-item',{attrs:{"to":"/news"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" NEWS ")])],2)])],1)],1)],1),_c('div',{staticClass:"redbanner"})]),_c('div',{staticClass:"videoTextBlock"},[(_vm.$route.name == 'home')?[(_vm.isMobileimage(true))?[_c('div',{staticClass:"mobileImage",style:({ 'background-image': 'url(' + _vm.homeSectionData.home_banner_back_ground_image + ')' })})]:[_c('img',{staticClass:"MainImage",attrs:{"src":_vm.homeSectionData.home_banner_back_ground_image}})],(this.$route.name === 'home')?[_c('div',{staticClass:"bannerTitle container"},[_c('h1',{staticClass:"BannerText"},[_vm._v(" "+_vm._s(_vm.homeSectionData.first_line_text)+" "),_c('br'),_c('span',{staticClass:"secondLine"},[_vm._v(" "+_vm._s(_vm.homeSectionData.second_line_text)+" ")]),_c('br'),_c('span',{staticClass:"secondLine"},[_vm._v(" "+_vm._s(_vm.homeSectionData.slogan)+" ")]),_c('br')])])]:_vm._e()]:_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }